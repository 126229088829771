import React, { useEffect, useState } from "react"
import "./App.css"
import {
  Link,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom"
import { isIOS } from "react-device-detect"

function VerifyEmail() {
  const location = useLocation()
  const verificationUrl = "https://app-dbm.firebaseapp.com/__/auth/action"
  const appUrl = "detbarnemad://verify-email"
  const browserURL = `${verificationUrl}${location.search}`
  const inAppURL = `${appUrl}${location.search}`

  function redirectToVerification() {
    window.location.href = browserURL
  }

  if (isIOS) {
    window.location.href = inAppURL
    return (
      <div className="hcontainer">
        <h1>Åbner Det Barnemad..</h1>
        <h2>
          Info: Hvis du ikke bruger Safari, bedes du åbne dette link i Safari i
          stedet for.
        </h2>
      </div>
    )
  }

  return (
    <>
      {isIOS ? (
        <>
          <h1>Bekræft emailadresse..</h1>
          <br />
          <button onClick={redirectToVerification}>
            Klik her for at bekræfte din email
          </button>
        </>
      ) : (
        <div className="hcontainer">
          <h1>Åbner Det Barnemad</h1>
          <h2>
            Info: Hvis du ikke bruger Safari, bedes du åbne dette link i Safari
            i stedet for.
          </h2>
        </div>
      )}
    </>
  )
}

function ResetPassword() {
  const location = useLocation()
  const appUrl = "detbarnemad://reset-password"

  const newURL = `${appUrl}${location.search}`

  window.location.href = newURL

  return (
    <div className="hcontainer">
      <h1>Åbner Det Barnemad..</h1>
      <h2>
        Info: Hvis du ikke bruger Safari, bedes du åbne dette link i Safari i
        stedet for.
      </h2>
    </div>
  )
}

function Page404() {
  return (
    <div className="text">
      <h1>404</h1>
      <h2>Siden findes ikke</h2>
    </div>
  )
}

function PrivacyPolicy() {
  const [policy, setPolicy] = useState("")

  useEffect(() => {
    fetch("https://api.detbarnemad.dk/app/terms")
      .then((res) => res.json())
      .then((data) => {
        const termsArray = splitTermsString(data.content.text)
        const formattedText = termsArray
          .map((term) => formatText(term))
          .join(" ")
        setPolicy(formattedText)
      })
  }, [])

  return (
    <div
      style={{
        height: "100%",
        overflowY: "scroll",
        backgroundColor: "white",
        margin: 40,
        borderRadius: 10,
        boxSizing: "border-box",
        padding: 40,
        width: 400,
        maxWidth: "90%",
      }}
    >
      <p
        style={{ padding: 0, fontSize: 16, lineHeight: 1.4, letterSpacing: 1 }}
        dangerouslySetInnerHTML={{ __html: policy }} // Change here
      />
    </div>
  )
}

function splitTermsString(text: string): string[] {
  const regex = /#([^#]+)#/g
  let result: string[] = []
  let match
  let lastIndex = 0

  while ((match = regex.exec(text))) {
    result.push(text.substring(lastIndex, match.index)) // Text before the match
    result.push(match[0]) // The match itself
    lastIndex = regex.lastIndex // Update the index for the next iteration
  }
  result.push(text.substring(lastIndex)) // Text after the last match

  return result.filter((item) => item !== "")
}

function formatText(string: string): string {
  const isBold = string.startsWith("#") && string.endsWith("#")
  const formattedString = isBold ? string.replace(/#/g, "") : string

  return isBold
    ? `<b>${formattedString}</b><br/>`
    : `${formattedString}<br/><br/>`
}

function MainRoute() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const mode = searchParams.get("mode")

  if (mode === "verifyEmail") {
    return <VerifyEmail />
  } else if (mode === "resetPassword") {
    return <ResetPassword />
  } else {
    return <Page404 />
  }
}

function App() {
  const router = createBrowserRouter([
    {
      path: "privatlivspolitik",
      element: <PrivacyPolicy />,
    },
    {
      path: "*",
      element: <MainRoute />,
    },
  ])

  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  )
}

export default App
